import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layouts/MainLayout"
import SEO from "../components/utils/Seo"
import { Link as MuiLink, Box, ButtonBase, Container, Grid, makeStyles, Typography } from "@material-ui/core"
import { Link } from 'gatsby'
import moment from 'moment-timezone'
import 'moment/locale/es';
import ImageDiv from "../components/utils/ImageDiv"
import Icon from '@mdi/react'
import { mdiCalendar } from '@mdi/js'

const recargasacuba = {
    naranja: 'rgb(238, 129, 60)',
    cubacel: 'rgb(0, 55, 141)',
    azul: 'rgb(101, 152, 228)',
    verde: 'rgb(103, 174, 85)',
};

const useStyle = makeStyles(theme => ({
    naranja: {
        color: recargasacuba.naranja,
    },
    azul: {
        color: recargasacuba.azul,
    },
    cubacel: {
        color: recargasacuba.cubacel,
    },
    image: {
        height: 300,
        backgroundPosition: 'top',
        backgroundSize: 'cover'
    },
    title: {
        fontSize: 42,
        color: recargasacuba.cubacel,
        marginTop: 8,
    },
    tags: {
        fontSize: 22,
        color: recargasacuba.azul,
        fontStyle: 'italic',
        marginTop: 8,
    },
    date: {
        fontSize: 18,
        marginTop: 8,
    },
    source: {
        fontStyle: 'italic',
        fontSize: 14,
    },
    content: {
        textAlign: 'justify',
        fontWeight: 300,
    },
    back: {
        color: recargasacuba.azul,
    },
    container: {
        background: 'white',
        [theme.breakpoints.only('xs')]: {
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
    },
    fanPage: {
        textAlign: 'center',
        marginTop: 8,
        [theme.breakpoints.only('xs')]: {
            marginLeft: theme.spacing(-1),
        },
    }
}))

const Template = (props) => {
    const classes = useStyle();
    const { markdownRemark } = props.data // data.markdownRemark holds your post data
    const { frontmatter, html, excerpt } = markdownRemark
    const pageIndex = Math.floor(props.pageContext.itemIndex / props.pageContext.itemsPerPage);
    const currentPage = pageIndex === 0 ? "/" : `/${pageIndex + 1}`;
    return (
        <Layout>
            <SEO title={frontmatter.title} description={frontmatter.description} image={frontmatter.image} preview={excerpt} />
            <div className={classes.toolbar} />
            <Container maxWidth="md" className={classes.container}>
                <Grid container direction="column">
                    <ImageDiv fileName={frontmatter.image}>
                        <Grid item className={classes.image} />
                    </ImageDiv>
                </Grid>
                <Grid item container alignItems="center">
                    <Grid item>
                        <Icon path={mdiCalendar}
                            style={{
                                marginTop: 8,
                                marginRight: 8,
                                color: 'rgba(0, 0, 0, 0.54)',
                            }}
                            size={1}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color="textSecondary" className={classes.date}>
                            {
                                `${moment.tz(frontmatter.date, "DD/MMMM, YYYY", 'Europe/Madrid').tz(moment.tz.guess(true)).format('LL')}`
                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography variant="h2" className={classes.tags}>Tags: {frontmatter.tags.map(item => (`${item}, `))}</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h1" className={classes.title}>{frontmatter.title}</Typography>
                </Grid>
                <Grid item>
                    <div
                        className={classes.content}
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </Grid>
                <Grid item>
                    <Typography variant="body1" color="textSecondary" className={classes.source}>
                        Fuente: {frontmatter.source}
                    </Typography>
                </Grid>
                <Grid item style={{ marginTop: 16 }}>
                    <Link to={currentPage} style={{ textDecoration: 'none' }}>
                        <ButtonBase>
                            <Typography variant="body1" className={classes.back} >← Volver</Typography>
                        </ButtonBase>
                    </Link>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 32 }}>
                    <Typography variant="body1" color="textSecondary" align="center"><i>Si te gusta el blog regálanos un <b>Me gusta</b> en nuestra <b>página de Facebook</b></i></Typography>
                </Grid>
                <Grid item xs={12} className={classes.fanPage}>
                    <iframe title="Recargas a Cuba" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Frecargasacubaapp%2F&tabs&width=340&height=70&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=861364457229667" width="340" height="70" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                </Grid>
                <Grid item xs={12} style={{ marginTop: -8 }}>
                    <Box display="flex" alignItems="center" p={2} flexDirection="column">
                        <MuiLink href="https://www.recargasacuba.app" style={{ marginTop: 4 }}>
                            <Typography variant="body1" color="textPrimary" align="center">&copy;2014-2020 www.recargasacuba.app</Typography>
                        </MuiLink>
                    </Box>
                </Grid>
            </Container>
        </Layout >
    )
}
export default Template

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 120)
      frontmatter {
        date(formatString: "DD/MMMM, YYYY")
        path
        title
        source
        description
        tags
        image
      }
    }
  }
`